<template>
  <div class="card card-custom">
    <div class="card-header" v-show="hasHeaderSlot">
      <div class="card-title h3 p-0 m-0 w-100">
        <slot name="header"></slot>
      </div>
    </div>
    <div class="card-body" :class="cardBodyClass">
      <slot></slot>
    </div>
    <div class="card-footer" v-show="hasFooterSlot">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    cardBodyClass: {
      Type: String,
      default: '',
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots['header'];
    },
    hasFooterSlot() {
      return !!this.$slots['footer'];
    },
  },
};
</script>
